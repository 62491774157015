<template>
  <svg
    class="folder-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 191 159"
  >
    <g id="open">
      <path
        class="folder-icon-outline"
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      ></path>
      <path
        class="folder-icon-bg"
        d="M159,30.5H82.5c-3,0-7-1-9-5s-4.67-6-8.83-6H31a12,12,0,0,0-12,12V127a12,12,0,0,0,12,12H159a12,12,0,0,0,12-12V42.5A12,12,0,0,0,159,30.5Z"
      ></path>
      <path
        class="folder-icon-outline"
        d="M172,43H44c-6.6,0-10.5,4.5-12,12s-12.5,65.5-13,72,5.4,12,12,12H159c6.6,0,10.5-3.5,12-12s11.5-64.5,13-72S178.6,43,172,43Z"
      ></path>
      <path
        class="folder-icon-fg"
        d="M172,43H44c-6.6,0-10.5,4.5-12,12s-12.5,65.5-13,72,5.4,12,12,12H159c6.6,0,10.5-3.5,12-12s11.5-64.5,13-72S178.6,43,172,43Z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style src="../styles/Folder.scss" lang="scss"></style>
